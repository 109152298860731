"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentPlayer = exports.isLetterboxPlayer = void 0;
var controls_1 = require("./controls");
var events_1 = require("./events");
var focus_1 = require("./focus");
var HlsJSPlayer_1 = require("./players/HlsJSPlayer");
var LetterboxPlayer_1 = require("./players/LetterboxPlayer");
var VideoJSPlayer_1 = require("./players/VideoJSPlayer");
var VideoTagPlayer_1 = require("./players/VideoTagPlayer");
var isLetterboxPlayer = function (player) { return player instanceof LetterboxPlayer_1.LetterboxPlayer; };
exports.isLetterboxPlayer = isLetterboxPlayer;
var currentPlayer = null;
var getCurrentPlayer = function () { return currentPlayer; };
exports.getCurrentPlayer = getCurrentPlayer;
var destroyPlayer = function () {
    if (!currentPlayer)
        return;
    (0, controls_1.destroyControls)();
    (0, events_1.detachEvents)();
    currentPlayer.destroy();
    currentPlayer = null;
    var videoContainer = document.getElementById('video');
    if (!videoContainer)
        return;
    videoContainer.innerHTML = '';
    var eventsContainer = document.getElementById('events');
    if (!eventsContainer)
        return;
    eventsContainer.innerHTML = '';
    var statsContainer = document.getElementById('stats');
    if (!statsContainer)
        return;
    statsContainer.innerHTML = '';
    var resolutionContainer = document.getElementById('resolution');
    if (!resolutionContainer)
        return;
    resolutionContainer.innerHTML = '';
    var errorContainer = document.getElementById('error');
    if (!errorContainer)
        return;
    errorContainer.innerHTML = '';
};
var initPlayer = function () {
    (0, controls_1.createControls)();
    (0, controls_1.createLoaders)();
    (0, events_1.attachEvents)();
};
var videoTagBtn = (0, focus_1.createFocusableButton)({
    row: 0,
    column: 0,
    label: 'Video tag',
    callback: function () {
        destroyPlayer();
        currentPlayer = new VideoTagPlayer_1.VideoTagPlayer();
        initPlayer();
    },
});
var videoJSBtn = (0, focus_1.createFocusableButton)({
    row: 0,
    column: 1,
    label: 'VideoJS 7.16.0',
    callback: function () {
        destroyPlayer();
        currentPlayer = new VideoJSPlayer_1.VideoJSPlayer();
        initPlayer();
    },
});
var letterboxBtn = (0, focus_1.createFocusableButton)({
    row: 0,
    column: 2,
    label: 'Letterbox 3.24.0 (VideoJS 7.16.0)',
    callback: function () {
        destroyPlayer();
        currentPlayer = new LetterboxPlayer_1.LetterboxPlayer();
        initPlayer();
    },
});
var hlsBtn = (0, focus_1.createFocusableButton)({
    row: 0,
    column: 3,
    label: 'HLS.js 1.4.3',
    callback: function () {
        destroyPlayer();
        currentPlayer = new HlsJSPlayer_1.HlsJSPlayer();
        initPlayer();
    },
});
var close = (0, focus_1.createFocusableButton)({
    row: 0,
    column: 4,
    label: '(x) Close',
    callback: function () {
        // delay to avoid parent receive "enter keyup" event and reopen the iframe
        setTimeout(function () {
            window.parent.postMessage('close', '*');
            window.close();
        }, 300);
    },
});
var playersContainer = document.getElementById('players');
playersContainer === null || playersContainer === void 0 ? void 0 : playersContainer.appendChild(videoTagBtn);
playersContainer === null || playersContainer === void 0 ? void 0 : playersContainer.appendChild(videoJSBtn);
playersContainer === null || playersContainer === void 0 ? void 0 : playersContainer.appendChild(letterboxBtn);
playersContainer === null || playersContainer === void 0 ? void 0 : playersContainer.appendChild(hlsBtn);
playersContainer === null || playersContainer === void 0 ? void 0 : playersContainer.appendChild(close);
var userAgent = document.getElementById('user-agent');
userAgent === null || userAgent === void 0 ? void 0 : userAgent.appendChild(document.createTextNode(navigator.userAgent));
