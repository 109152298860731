"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detachEvents = exports.attachEvents = void 0;
var main_1 = require("./main");
var stats_1 = require("./stats");
var EVENTS = [
    'abort',
    'canplay',
    'canplaythrough',
    'durationchange',
    'emptied',
    'ended',
    'error',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    // 'progress',
    'ratechange',
    'resize',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    // 'timeupdate',
    'transitionend',
    'volumechange',
    'waiting',
];
var eventsContainer = document.getElementById('events');
var MAX_NB_EVENTS = 10;
var showEvent = function (event) {
    var _a, _b, _c, _d, _e;
    if (event.type === 'canplay' || event.type === 'canplaythrough') {
        (0, stats_1.loadEnd)();
    }
    if (event.type === 'error') {
        var error = document.getElementById('error');
        if (error) {
            var message = 'unknown';
            var player = (0, main_1.getCurrentPlayer)();
            if ((_a = player === null || player === void 0 ? void 0 : player.player) === null || _a === void 0 ? void 0 : _a.error()) {
                message = "".concat((_c = (_b = player === null || player === void 0 ? void 0 : player.player) === null || _b === void 0 ? void 0 : _b.error()) === null || _c === void 0 ? void 0 : _c.code, " - ").concat((_e = (_d = player === null || player === void 0 ? void 0 : player.player) === null || _d === void 0 ? void 0 : _d.error()) === null || _e === void 0 ? void 0 : _e.message);
            }
            error.innerHTML = "Error: ".concat(message);
        }
    }
    (0, stats_1.showResolution)();
    if ((eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children) && (eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children.length) > MAX_NB_EVENTS) {
        for (var i = 0; i < (eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children.length) - MAX_NB_EVENTS; i++) {
            eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children[i].remove();
        }
    }
    var eventSpan = document.createElement('span');
    eventSpan.innerHTML = "".concat(event.type, " ");
    eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.appendChild(eventSpan);
};
var attachEvents = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    EVENTS.forEach(function (event) {
        player.attachEvent(event, showEvent);
    });
};
exports.attachEvents = attachEvents;
var detachEvents = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    EVENTS.forEach(function (event) {
        player.detachEvent(event, showEvent);
    });
};
exports.detachEvents = detachEvents;
